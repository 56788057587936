import { axios } from '../../axios/AxiosConfig';
import { USER_PROFILE_INVESTORS_API } from '../apiEndPoints';
import { handleError } from '../../utilities/handleError';

export interface Company {
  _id: string;
  companyName: string;
  companyLogo: string;
  companySlug: string;
  propertyType: string;
}

export interface Campaign {
  _id: string;
  equityCurrencyCode: string;
  equityCurrencySymbol: string;
  companyId: string;
  campaignImageURL: string;
  campaignVideoURL: string;
  uploadType: string;
}

export interface Investment {
  _id: string;
  user: string;
  campaignId: Campaign;
  amount: number;
  createdAt: string;
  companyId: Company;
}

export interface InvestmentData {
  docs: Investment[];
  totalInvestments: number;
  displayLoadMore: boolean;
}

export const getAllInvestments = async (
  userId: string,
): Promise<InvestmentData> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(USER_PROFILE_INVESTORS_API + userId);
      resolve(response.data?.data?.data);
    } catch (error: any) {
      handleError(error);
      console.log(error);
      reject(error);
    }
  });
};
