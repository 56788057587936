import { useState } from 'react';
import { Container, Button, Form, Card, CardBody } from 'react-bootstrap';
import { Header } from '../../component';
import { IoChevronBackOutline } from 'react-icons/io5';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { resetPasswordService } from '../../services/auth/resetPasswordService';
import { toast } from 'react-toastify';

function ResetPassword() {
  const [passwordCredentials, setPasswordCredentials] = useState({
    password: '',
    passwordConfirm: '',
  });
  const [passwordError, setPasswordError] = useState({
    password: false,
    passwordConfirm: false,
  });
  const [eye, setEye] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();
  const Eye = () => {
    setEye(!eye);
  };

  const isError = () =>
    passwordError?.password ||
    passwordError?.passwordConfirm ||
    passwordCredentials?.password === '' ||
    passwordCredentials?.passwordConfirm === '';

  const resetPass = () => {
    if (!token) {
      toast.error('Invalid URL or token');
      return;
    }
    resetPasswordService({ ...passwordCredentials, token })
      .then((result) => {
        if (result) {
          setTimeout(() => {
            toast.success('Reset password request sent');
          }, 2000); // 2000 milliseconds = 2 seconds
          navigate('/login');
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div>
      <Header />
      <div className="h-lg-calc vstack login-h-full login-background background-no-repeat bg-center bg-cover">
        <Container className="my-auto">
          <Card className="border-0 max-w-screen-md mx-auto">
            <CardBody>
              <div className="max-w-screen-400 mx-auto">
                <h2 className="text-center mb-1 font-bolder">Reset Password</h2>
                <p className="text-center text-dark mb-12">
                  Let`s get that password updated! Enter your
                  <br /> new one here.
                </p>
                <Form>
                  <div className="vstack gap-3 mb-10">
                    <Form.Group
                      controlId="floatingInput2"
                      className="position-relative form-floating"
                    >
                      <Form.Control
                        type={eye ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Password*"
                        name="password"
                        value={passwordCredentials.password}
                        onChange={(event) => {
                          setPasswordCredentials((prev) => ({
                            ...prev,
                            password: event.target.value,
                          }));
                          if (
                            !event.target.value ||
                            event.target.value === ''
                          ) {
                            setPasswordError((prev) => ({
                              ...prev,
                              password: true,
                            }));
                          } else
                            setPasswordError((prev) => ({
                              ...prev,
                              password: false,
                            }));
                        }}
                      />
                      {!eye ? (
                        <FaEye onClick={Eye} className="fa-eye" />
                      ) : (
                        <FaEyeSlash onClick={Eye} className="fa-eye" />
                      )}
                      <Form.Label htmlFor="floatingInput2">
                        New Password
                      </Form.Label>
                      {passwordError.password && (
                        <p className="text-danger mt-1">
                          {/* <Image src={ErrorFill} /> */}
                          Invalid password.
                        </p>
                      )}
                    </Form.Group>
                    <Form.Group
                      controlId="floatingInput2"
                      className="position-relative form-floating"
                    >
                      <Form.Control
                        type={'password'}
                        className="form-control"
                        placeholder="Password*"
                        name="password"
                        value={passwordCredentials?.passwordConfirm}
                        onChange={(event) => {
                          setPasswordCredentials((prev) => ({
                            ...prev,
                            passwordConfirm: event.target.value,
                          }));
                          if (
                            !event.target.value ||
                            event.target.value !== passwordCredentials?.password
                          ) {
                            setPasswordError((prev) => ({
                              ...prev,
                              passwordConfirm: true,
                            }));
                          } else
                            setPasswordError((prev) => ({
                              ...prev,
                              passwordConfirm: false,
                            }));
                        }}
                      />
                      <FaEyeSlash className="fa-eye" />
                      <Form.Label htmlFor="floatingInput2">
                        Re-enter Password
                      </Form.Label>
                      {passwordError.passwordConfirm && (
                        <p className="text-danger mt-1">
                          {/* <Image src={ErrorFill} /> */}
                          Passwords does not match
                        </p>
                      )}
                    </Form.Group>
                  </div>
                  <div className="text-center">
                    <Button
                      disabled={isError()}
                      onClick={() => resetPass()}
                      variant="primary"
                      className="rounded-pill btn-lg w-full py-4 mb-6 font-semibold text-sm"
                    >
                      Reset
                    </Button>
                    <Link
                      to="/login"
                      className="text-dark text-sm font-semibold"
                    >
                      <IoChevronBackOutline className="text-gray text-md" />{' '}
                      Back to Login
                    </Link>
                  </div>
                </Form>
              </div>
              <div className="mt-8 d-flex justify-content-center">
                <p className="font-semibold text-dark">
                  Terms & conditions line
                </p>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </div>
  );
}

export default ResetPassword;
