import React, { useState } from 'react';
import {
  Modal,
  Button,
  Image,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../../redux/store/type';
import { REACT_APP_AWS_URL } from '../../../utilities/envConstants';
import useGetUserWalletBalance from '../../../hooks/useGetUserWalletBalance';
import { formatAmount } from '../../util';
import useInvestInProperty from '../../../hooks/useInvestInProperty';

const InvestmentModal = ({
  show,
  handleClose,
  selectedUnits,
}: {
  show: boolean;
  handleClose: any;
  selectedUnits: number;
}) => {
  const [units, setUnits] = useState(selectedUnits);
  const { isSetUp, balance } = useSelector((state: RootState) => state.wallet);
  const { isLoading } = useGetUserWalletBalance();
  const { isInProgress, makeInvestmentRequest } = useInvestInProperty();

  const property = useSelector(
    (state: RootState) => state.properties.selectedProperty,
  );

  const unitPrice = Number(property?.pricePerShare);
  const totalUnits = property?.availableShares || 0;
  const handleUnitChange = (change) => {
    setUnits((prevUnits) =>
      Math.max(0, Math.min(prevUnits + change, totalUnits)),
    );
  };

  // TODO: Extend this
  const calculateFinalAmount = () => {
    return (units * unitPrice).toFixed(2);
  };

  const handleSubmit = async () => {
    const response = await makeInvestmentRequest({
      campaignId: property?.id,
      noOfPropUnit: units,
      amount: calculateFinalAmount(),
    });
    // Request was successful. We can close the model.
    if (response) {
      handleClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      dialogClassName="modal-80w"
    >
      <Modal.Header className="border-bottom-0 px-10 pt-6">
        <Modal.Title className="text-xl">INVEST IN UNITS</Modal.Title>
      </Modal.Header>
      {!isInProgress ? (
        <Modal.Body className="px-10">
          {/* [TODO]: Refactor wallet balance logic to clean this */}
          {isSetUp ? (
            <div>
              <div className="text-gray-600 mb-1 font-bold">
                Current Balance in Wallet
              </div>
              <div className="d-flex justify-content-between mb-4">
                <div>
                  <div className="text-xl font-bold text-dark">
                    {formatAmount(balance)}
                  </div>
                  <div>
                    <p className="font-italic text-xs text-dark font-bold">
                      **Min. CAD $10 & Max. CAD $2,500
                    </p>
                  </div>
                </div>
                <Link
                  to={'/wallet?showTopUpModal=true'}
                  className="text-underline d-flex align-items-center text-center font-bold"
                  style={{ color: '#7D4CDB' }}
                >
                  + Add Balance
                </Link>
              </div>
            </div>
          ) : isLoading ? (
            <div className="w-full d-flex justify-content-center">
              <Spinner />
            </div>
          ) : (
            <></>
          )}
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex ">
              <Image
                src={`${REACT_APP_AWS_URL}/${property?.campaignImageURL}`}
                className="rounded-3"
                style={{ width: '8rem' }}
              />
              <div className="d-flex align-items-center ms-4">
                <div>
                  <h6>{property?.companyId?.companyName}</h6>
                  <p className="text-sm text-gray-500 font-bold mt-1">
                    {formatAmount(property?.pricePerShare)}/Unit
                  </p>
                </div>
              </div>
            </div>
            <div>
              <InputGroup className="shadow-none h-10 justify-content-between align-items-center mb-2">
                <Button
                  variant="outline-dark"
                  style={{ border: '2px solid #D9D9D9' }}
                  className="btn rounded-circle w-8 h-8 py-4 me-3 d-flex align-items-center justify-content-center text-lg"
                  onClick={() => handleUnitChange(-1)}
                >
                  -
                </Button>
                <Form.Control
                  type="text"
                  value={units}
                  readOnly
                  className="text-center h-10 me-3"
                  style={{
                    maxWidth: '70px',
                    border: '2px solid #D9D9D9',
                    borderRadius: '5px',
                  }}
                />
                <Button
                  className="btn rounded-circle w-8 h-8 py-4 d-flex align-items-center justify-content-center text-lg"
                  variant="outline-dark"
                  style={{ border: '2px solid #D9D9D9' }}
                  onClick={() => handleUnitChange(1)}
                >
                  +
                </Button>
              </InputGroup>
              <div className="text-end font-bold text-gray-500 text-sm">
                Avail. Units: {totalUnits}
              </div>
            </div>
          </div>
          <hr />
          <div
            style={{ paddingLeft: '9rem' }}
            className="d-flex justify-content-between"
          >
            <p className="text-gray-500 font-bold">Total</p>
            <h5>CAD ${calculateFinalAmount()}</h5>
          </div>
        </Modal.Body>
      ) : (
        <div className="w-full d-flex justify-content-center">
          <Spinner />
        </div>
      )}
      <Modal.Footer className="border-top-0 px-6 pb-6">
        <Button
          variant="outline-dark"
          style={{ border: '1px solid #D9D9D9' }}
          className="rounded-pill px-8 btn-light"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          variant="primary"
          disabled={!isSetUp || +calculateFinalAmount() > balance}
          style={{ border: '1px solid #D9D9D9' }}
          className="rounded-pill btn-lg px-10"
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvestmentModal;
