import { Footer, Header } from '../../component';
import { Col, Container, Row } from 'react-bootstrap';
import {
  WealthCreator,
  MetroVillas,
  IncomeGenerator,
  Leigh,
  KaustavGhosh,
  RobArthurs,
} from '../../assets/image';
import { TeamMemberCard } from './TeamMemberCard';
import './OurTeam.scss';
import { useState } from 'react';
import { AdvisorCard } from './AdvisorCard';

function OurTeam() {
  const [active, setActive] = useState(0);

  const teamMemebers = [
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: MetroVillas,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: IncomeGenerator,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: WealthCreator,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: MetroVillas,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kam Pawar',
      role: 'Position',
      image: IncomeGenerator,
      linkedin: 'https://linkedin.com',
    },
  ];

  const boardMembers = [
    {
      name: 'Leigh',
      role: 'Advisor',
      desc: "A serial entrepreneur since 2002 and Simon Fraser University alumnus, Leigh has founded or co-founded six active companies in the areas of real estate technology, IT security, social gaming, hospitality and data science. His predominant focus is that of corporate finance, business development & administration management.\
        <br/><br/>Leigh is the founder of Access Control & Property Manage-ment company Mondofi Technology Inc. With it he brings <b>5 years of experience</b> in the purpose-built rental housing market and pledges below-market access to the company’s entire stack of rental housing property management software for the benefit of Frontier Housing's properties.",
      image: Leigh,
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Kaustav Ghosh',
      role: 'Advisor',
      image: KaustavGhosh,
      desc: "Kaustav Ghosh, A seasoned business coach, consultant, and mentor with <b>7+ years of experience</b> supporting <b>1,500+ businesses</b> globally. Passionate about empowering entrepreneurs, His focus on mindset development and offer proprietary brand-building solutions. A sought-after trainer and speaker, Have inspired <b>150,000+ individuals</b>. Beyond business.\
        <br/><br/>Culinary and yoga enthusiast an avid traveler. Founder of Vancouver's POTLUCK community and author of (thegreatindiantravel.com) (19.8k km, 101 days, 28 provinces). On a mission to support small businesses and startups through his Initiative <b>#ISupportYourBusiness</b> and <b>#ISupportYou</b>.",
      linkedin: 'https://linkedin.com',
    },
    {
      name: 'Rob Arthurs',
      role: 'Advisor',
      image: RobArthurs,
      desc: 'With over <b>36 years of experience</b> across diverse industries, Rob Arthurs is a seasoned executive and entrepreneur, having <b>founded 14 companies</b> and spent the last 23 years driving business growth, global expansion, and operational success.\
        <br/><br/>As a business coach, Rob has helped some of Canada’s top realtors excel in competitive markets. He’s also a member and speaker at the Real Estate Investment Network (REIN), sharing insights on business development.\
        <br/><br/>During his 14 years with the British Columbia government, Rob led trade missions, developed global partnerships, and played a key role in securing over <b>$1 billion</b> in procurement contracts during the 2010 Winter Olympics.',
      linkedin: 'https://linkedin.com',
    },
  ];

  return (
    <div>
      <Header />
      {/*<Container>*/}
      <div className="py-5 px-2 px-md-18 px-lg-18 pb-18">
        <Row>
          <Col md={4}>
            <div className="d-flex flex-column px-10 py-16 p-sm-20 align-items-center mb-8">
              <h1 className="mb-4 display-3 font-bold">
                <span className="font-light">Our</span>
                <span> Board of Advisors</span>
              </h1>
              <div className="sub-title">
                Our Board of Advisors brings unparalleled expertise and
                strategic vision to guide our journey.
              </div>
            </div>
          </Col>
          {boardMembers.map((m, i) => (
            <Col md={active == i ? 4 : 2} className="">
              <AdvisorCard
                onClick={() => setActive(i)}
                active={active == i}
                {...m}
              />
            </Col>
          ))}
        </Row>
      </div>
      <div className="my-5 mx-5 mx-md-18 mb-18 bg-light-purple rounded-5">
        <div className="d-flex flex-column px-10 py-16 p-sm-20 text-center align-items-center mb-8">
          <h1 className="mb-4 display-3 font-bold">
            <span className="font-light">Meet our</span>
            <span> Team Members</span>
          </h1>
          <div className="sub-title">
            Our team combines diverse skills and expertise to drive innovation
            and achieve excellence.
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          {teamMemebers.map((m) => (
            <TeamMemberCard {...m} />
          ))}
        </div>
      </div>
      {/* <div className="py-5 px-2 px-md-18 px-lg-18 pb-18">
        <div className="d-flex bg-light-blue rounded-5 flex-column px-10 py-16 p-sm-20 text-center align-items-center mb-8">
          <h1 className="mb-4 display-3 font-bold">
            <div className="font-light">Our</div>
            <span> Board of Advisors</span>
          </h1>
          <div className="sub-title">
            Our Board of Advisors brings unparalleled expertise and strategic
            vision to guide our journey.
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center pb-16">
          {boardMembers.map((m) => (
            <TeamMemberCard {...m} />
          ))}
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default OurTeam;
