import { Card } from 'react-bootstrap';
import { AiFillLinkedin } from 'react-icons/ai';

export function AdvisorCard(props) {
  return (
    <Card
      onClick={props.onClick}
      className={`border-dark border-bottom-2 advisor-card ${
        props.active ? 'card-active' : 'card-inactive'
      }`}
      style={{ borderBottom: '4px solid black' }}
    >
      <Card.Img
        variant="top"
        src={props.image}
        className="advisor-card-image"
      />
      {props.active && (
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div>
              <Card.Text className="text-dark text-bold text-lg">
                {props.name}
              </Card.Text>
              <Card.Text className="text-sm text-dark">{props.role}</Card.Text>
            </div>
            <a href={props.linkedin} target="_blank">
              <AiFillLinkedin className="text-xl" color="#0077b5" />
            </a>
          </div>
          {props.desc && (
            <div className="mt-4">
              <Card.Text
                className="text-dark text-sm"
                dangerouslySetInnerHTML={{ __html: props.desc }}
              ></Card.Text>
            </div>
          )}
        </Card.Body>
      )}
    </Card>
  );
}
